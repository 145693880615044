export const matchsData = [
  {
    id: 1,
    date: "20",
    hours: "",
    month: "Septembre",
    year: "2024",
    fullDate: "9/20/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 4,
    homeBasedTeamScore: "68",
    outsideTeamScore: "66",
  },
  {
    id: 2,
    date: "28",
    hours: "",
    month: "Septembre",
    year: "2024",
    fullDate: "9/28/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 17,
    homeBasedTeamScore: "69",
    outsideTeamScore: "83",
  },
  {
    id: 3,
    date: "01",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/01/2024",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: true,
    opponentId: 20,
    homeBasedTeamScore: "84",
    outsideTeamScore: "54",
  },
  {
    id: 4,
    date: "06",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/06/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 9,
    homeBasedTeamScore: "104",
    outsideTeamScore: "96",
  },
  {
    id: 5,
    date: "11",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/11/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 14,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 6,
    date: "15",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/15/2024",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: false,
    opponentId: 21,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 7,
    date: "19",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/19/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 22,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 8,
    date: "27",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/27/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 6,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 9,
    date: "29",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/29/2024",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: true,
    opponentId: 23,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 10,
    date: "02",
    hours: "",
    month: "Novembre",
    year: "2024",
    fullDate: "11/02/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 11,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 11,
    date: "10",
    hours: "",
    month: "Novembre",
    year: "2024",
    fullDate: "11/10/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 3,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 12,
    date: "13",
    hours: "",
    month: "Novembre",
    year: "2024",
    fullDate: "11/13/2024",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: false,
    opponentId: 23,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 13,
    date: "16",
    hours: "",
    month: "Novembre",
    year: "2024",
    fullDate: "11/16/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 13,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 14,
    date: "29",
    hours: "",
    month: "Novembre",
    year: "2024",
    fullDate: "11/29/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 8,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 15,
    date: "07",
    hours: "",
    month: "Décembre",
    year: "2024",
    fullDate: "12/07/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 7,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 16,
    date: "11",
    hours: "",
    month: "Décembre",
    year: "2024",
    fullDate: "12/11/2024",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: false,
    opponentId: 20,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 17,
    date: "14",
    hours: "",
    month: "Décembre",
    year: "2024",
    fullDate: "12/14/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 10,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 18,
    date: "18",
    hours: "",
    month: "Décembre",
    year: "2024",
    fullDate: "12/18/2024",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: true,
    opponentId: 21,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  }
  
];
